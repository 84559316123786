<template>
  <div>
    <InvitationModel
      @setAcceptInvitationDialog="acceptInvitationDialog = $event"
      @accepteInvitaion="handleAcceptreject('accept')"
      @rejectInvitaion="handleAcceptreject('reject')"
      :acceptInvitationDialog="true"
      :isinvitationLoading="isinvitationLoading"
    />
  </div>
</template>

<script>
import InvitationModel from '@/components/core/InvitationModel'
import { customersService, userService } from '@/services'
import { mapMutations } from 'vuex'
export default {
  name: 'Invitation',
  components: {
    InvitationModel
  },
  data() {
    return {
      isinvitationLoading: false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    handleAcceptreject(response) {
      if (this.isEmployeeInvitation) {
        return this.handleAccepteRejectEmpInvitaion(response)
      }
      return this.handleAccepteRejectCustomerInvitaion(response)
    },
    async handleAccepteRejectCustomerInvitaion(response) {
      const body = {
        accountId: this.$route?.query?.invitation,
        customerId: this.$route?.query?.invitation,
        envId: this.$route.query?.env
      }
      try {
        await customersService.accepteRejectCustomerInvitaion(response, body)
        this.addNotification({
          text:
            response === 'accept'
              ? 'تم قبول الدعوة, قم بتسجيل الدخول'
              : ' تم رفض الدعوة',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من معالجه الدعوه برجاء المحاوله لاحقاً',
          color: 'error'
        })
      } finally {
        this.acceptInvitationDialog = false
        this.$router.push({
          path: response === 'accept' ? '/auth/login' : '/',
          query:
            response === 'accept'
              ? { phoneNumber: this.$route.query.phoneNumber }
              : {}
        })
      }
    },

    async handleAccepteRejectEmpInvitaion(response) {
      const body = {
        environmentId: this.$route.query?.env,
        response
      }
      try {
        await customersService.accepteRejectEmpInvitaion(
          this.$route?.query?.invitation,
          body
        )
        this.addNotification({
          text: ' تم قبول الدعوة, قم بتسجيل الدخول',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من قبول الدعوه برجاء المحاوله لاحقاً',
          color: 'error'
        })
      } finally {
        this.acceptInvitationDialog = false
        this.$router.push({
          path: '/auth/login',
          query: { phoneNumber: this.$route.query.phoneNumber }
        })
      }
    },

    async checkInvitation(userId, envId) {
      this.isinvitationLoading = true
      try {
        const { data } = this.isEmployeeInvitation
          ? await userService.getUserInvitation({
              invitationId: userId,
              envId
            })
          : await customersService.clientInvitationStatus(userId, envId)
        const { isInvited, isRegistered, user } = data

        let isRegisteredUser = false
        if (user.hasOwnProperty('accountId') && user?.accountId) {
          isRegisteredUser = true
        }

        if (isRegisteredUser) {
          if (!isInvited && isRegistered) {
            this.addNotification({
              text: 'تم قبول الدعوة مسبقاً , قم بتسجيل الدخول',
              color: 'success'
            })
            this.acceptInvitationDialog = false
            this.$router.push({
              path: '/auth/login',
              query: { phoneNumber: user?.phoneNumber }
            })
          } else if (!isInvited && !isRegistered) {
            this.addNotification({
              text: 'تم رفض الدعوة مسبقاً',
              color: 'success'
            })
            this.acceptInvitationDialog = false
            this.$router.push('/')
          }
        } else {
          this.addNotification({
            text: 'قم بإتمام عملية التسجيل لقبول الدعوة',
            color: 'success'
          })
          localStorage.setItem('user-invitation', JSON.stringify(user))
          this.$router.push({
            path: `/auth/login?auth=register&phoneNumber=${user?.phoneNumber}`,
            query: {
              env: user?.environments[0]?._id,
              email: user?.email,
              name: user?.name,
              phoneNumber: user?.phoneNumber
            }
          })
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل بيانات الدعوة, حاول في وقت لاحق',
          color: 'error'
        })
        this.$router.push('/')
      } finally {
        this.isinvitationLoading = false
        this.acceptInvitationDialog = false
      }
    }
  },
  computed: {
    isEmployeeInvitation() {
      return !!(this.$route.query?.invitationType === 'employee')
    }
  },
  created() {
    this.checkInvitation(this.$route.query.invitation, this.$route.query.env)
  }
}
</script>
